import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const socialList = [
  {
    link: "#",
    iconName: "icofont-facebook",
    className: "facebook",
  },
  {
    link: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    link: "#",
    iconName: "icofont-linkedin",
    className: "linkedin",
  },
  {
    link: "#",
    iconName: "icofont-instagram",
    className: "instagram",
  },
  {
    link: "#",
    iconName: "icofont-pinterest",
    className: "pinterest",
  },
];

const Cgv = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        title={'Conditions Générales de Vente de l\'Application "Maysortie"'}
        curPage={"cgv"}
      />
      <div className="blog-section blog-single padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <article>
                <div className="section-wrapper">
                  <div className="row row-cols-1 justify-content-center g-4">
                    <div className="col">
                      <div className="post-item style-2">
                        <div className="post-inner">
                          <div className="post-thumb">
                            {/* <img src="assets/images/blog/single/01.jpg" alt="blog thumb rajibraj91" className="w-100" /> */}
                          </div>
                          <div className="post-content">
                            {/* <h2>Interactively Morph High Standards Anding</h2> */}
                            {/*                                                         <div className="meta-post">
                                                            <ul className="lab-ul">
                                                                <li><a href="#"><i className="icofont-calendar"></i>April 23,2021</a></li>
                                                                <li><a href="#"><i className="icofont-ui-user"></i>Rajib Raj</a></li>
                                                                <li><a href="#"><i className="icofont-speech-comments"></i>09 Comments</a></li>
                                                            </ul>
                                                        </div> */}
                            <p>
                              Bienvenue sur l'application "Mayosortie" ! Nous
                              sommes ravis que vous ayez choisi notre
                              application pour découvrir les événements festifs
                              et culturels à Mayotte. Avant de commencer à
                              utiliser notre service, veuillez lire
                              attentivement les conditions générales de vente
                              ci-dessous. En utilisant notre application, vous
                              acceptez automatiquement les termes et conditions
                              énoncés ci-dessous.
                            </p>

                            <p>
                              <p>
                                <h3>1. Définitions </h3>
                                <br></br>Annonce : désigne l'ensemble
                                des éléments et données (texte, visuel ou
                                photo), déposé par un annonceur sous sa
                                responsabilité éditoriale exclusive, en vue
                                d’annoncer un événement. 
                                <br></br><br></br>{" "}{" "}
                                • Annonceur : désigne
                                une entité, association ou entreprise, ayant
                                déposé une annonce sur l’application Maysortie.
                                <br></br><br></br>{" "}{" "}
                                • Évènement : Manifestation ayant une date/heure
                                de début et une date/heure de fin, ouverte au
                                public, ayant un caractère festif, culturel, ou
                                sportif, et présenté par l’Annonceur dans son
                                Annonce. 
                                <br></br><br></br>{" "}{" "}
                                • Utilisateur : désigne tout visiteur
                                consultant les services de l’application
                                Maysortie depuis un ordinateur, une tablette, un
                                smartphone ou tout autre support électronique.
                              </p>{" "}
                            </p>

                            <p>
                              <h3>2. Objet L'application "Maysortie" </h3>a pour objet de
                              fournir un calendrier des événements festifs,
                              culturels, sportifs et touristiques se déroulant à
                              Mayotte. Elle permet aux utilisateurs de
                              découvrir, de planifier et de participer à ces
                              divers événements. 
                              <br></br>
                              <h3>3. Utilisation de l'Application</h3>
                              3.1. Inscription : Pour déposer une annonce sur
                              l'application "Maysortie", vous devez vous
                              inscrire en créant un compte utilisateur. Vous
                              devez fournir des informations exactes et à jour
                              lors de l'inscription. 
                              <br></br><br></br>{" "}{" "}
                              3.2. Tarifs : L'utilisation
                              de base de l'application est gratuite, mais la
                              mise en ligne des annonces est payante. Les tarifs
                              et modalités de paiement seront clairement
                              indiqués avant de souscrire à une commande ou à un
                              abonnement.                        
                                     <br></br><br></br>{" "}{" "}Le règlement s’effectue par carte
                              bancaire. Lors de l’enregistrement de la commande
                              ou de l’abonnement, l’annonceur devra verser
                              l’intégralité du montant. 
                              <br></br><br></br>{" "}{" "}
                              <h3>4. Propriété</h3>
                              Intellectuelle Tous les droits de propriété
                              intellectuelle relatifs à l'application
                              "Maysortie" (y compris les contenus, les images et
                              la marque) appartiennent à l'éditeur de
                              l'application. Vous ne pouvez pas utiliser,
                              copier, reproduire ou distribuer ces éléments sans
                              autorisation écrite.                             
                              
                                <br></br><br></br>{" "}{" "}<h3>5. Confidentialité</h3> Nous nous
                              engageons à protéger vos données personnelles
                              conformément à notre politique de confidentialité.
                              En utilisant notre application, vous acceptez les
                              termes de notre politique de confidentialité. 
                              <br></br><br></br>{" "}{" "}
                              <h3>6.
                              Responsabilité</h3> L'application "Maysortie" fournit
                              des informations sur les événements, mais ne
                              garantit pas leur exactitude ni leur
                              disponibilité. 
                              <br></br><br></br>{" "}{" "}
                              Nous ne sommes pas responsables des
                              éventuels changements, annulations ou litiges liés
                              aux événements répertoriés. 
                              <br></br><br></br>{" "}{" "}
                              <h3>7. Modification des
                              Conditions Générales de Vente</h3> Nous nous réservons
                              le droit de modifier ces conditions générales à
                              tout moment. Les modifications seront publiées sur
                              l'application et prendront effet immédiatement
                              après leur publication. Il vous incombe de
                              consulter régulièrement ces conditions. 
                              <br></br><br></br>{" "}{" "}
                              <h3>8.Résiliation</h3> Vous pouvez résilier votre compte
                              utilisateur à tout moment en nous contactant. Nous
                              nous réservons le droit de résilier votre compte
                              en cas de violation de ces conditions générales ou
                              d’annonce illicite. 
                              <br></br><br></br>{" "}{" "}
                              <h3>9. Contact Pour toute question</h3>
                              ou demande d'assistance, vous pouvez nous
                              contacter à l'adresse suivante :

                              maysortie@gmail.com En utilisant l'application
                              "Maysortie", vous reconnaissez avoir lu et accepté
                              ces conditions générales de vente. 
                              <br></br><br></br>{" "}{" "}
                              Nous vous
                              remercions de votre confiance et espérons que
                              notre application vous permettra de profiter
                              pleinement des événements à Mayotte.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Cgv;
